.footer {
  width: 100%;
  background-color: #9CB833;
  color: black;
  text-align: center;
}

.muestra {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.padding-carousel {
  padding: 5%;
}

.responsive-carousel {
  width: 90%;
  height: auto;
}

.responsive {
  width: 100%;
  height: auto;
}

.responsive-blog {
  width: 100%;
  height: auto;
}

/* Buttons =============================================================================*/

/* Estructura botones --------------------------------------------------------------*/
.PageButton {
	box-shadow: 0px 10px 14px -7px #4d3a1a;
	border-radius:42px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-weight:bold;
  padding:0px 8%;
	text-decoration:none;
  text-shadow:0px 0px 0px #3d768a;
}
.PageButton:hover {
  background-color:#256fde;
  text-decoration: none;
}
.PageButton:active {
	position:relative;
	top:1px;
}

/* Estructura botones Agua-María --------------------------------------------------------------*/
.PageButtonAguaMaria {
	box-shadow: 0px 10px 14px -7px #000000;
	border-radius:42px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-weight:bold;
	padding:0px 8%;
	text-decoration:none;
	text-shadow:0px 0px 0px #3d768a;
}
.PageButtonAguaMaria:hover {
  background-color:#256fde;
  text-decoration: none;
}
.PageButtonAguaMaria:active {
	position:relative;
	top:1px;
}

/* Estructura botones de Blogs --------------------------------------------------------------*/
.PageButtonBlog { /*
	-moz-box-shadow: 0px 10px 14px -7px #009979;
	-webkit-box-shadow: 0px 10px 14px -7px #009979;
	box-shadow: 0px 10px 14px -7px #000000; */
	border-radius:42px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-weight:bold;
	padding:0px 2.5%;
	text-decoration:none;
	text-shadow:0px 0px 0px #3d768a;
}
.PageButtonBlog:hover {
  background-color:#256fde;
  text-decoration: none;
}
.PageButtonBlog:active {
	position:relative;
	top:1px;
}

/* Margin Responsivo -------------*/
/* Small devices (tablets, 768px and up) */
.pagebutton-margin {
  margin: 8%;
  padding: 3.5%
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pagebutton-margin {
    margin-bottom: 8%;
    margin-left: 0%;
    padding: 2%
  }
}


/* Estructura botones --------------------------------------------------------------*/
.PageButtonNavbar {
	box-shadow: 0px 10px 14px -7px #4d3a1a;
	border-radius:42px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	padding:0px 8%;
	text-decoration:none;
	text-shadow:0px 0px 0px #3d768a;
}
.PageButtonNavbar:hover {
  background-color:#256fde;
  text-decoration: none;
}
.PageButtonNavbar:active {
	position:relative;
	top:1px;
}

/* Boton Titulo Responsive -------------*/
/* Small devices (tablets, 768px and up) */
.pagebutton-margin {
  margin-top: 1%
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .pagebutton-margin {
    margin-top: 3%
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pagebutton-margin {
    margin-top: 3%;
    padding-top: 0.1%;
    padding-bottom: 0.1%;
    padding-left: 6%;
    padding-right: 6%;
  }
}

/* Count Num height Responsive ===========================================*/

.countnum-height {
  padding-top: 2%;
  padding-bottom: 2%;
}

/* Div Titulo height Responsive ===========================================*/

.div-titulo-height {
  padding-top: 80px;
  padding-bottom: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .div-titulo-height {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .div-titulo-height {
    padding-top: 160px;
    padding-bottom: 40px;
  }
}

/* Div Clientes height Responsive ===========================================*/

.div-clientes-height {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .div-clientes-height {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .div-clientes-height {
    padding-top: 110px;
    padding-bottom: 40px;
  }
}

/* Div Contacto height Responsive ===========================================*/

.div-contacto-height {
  padding-top: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .div-contacto-height {
    padding-top: 100px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .div-contacto-height {
    padding-top: 110px;
  }
}

/* Div height Responsive ===========================================*/

.div-height {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .div-height {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .div-height {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .div-height {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

/* Div Titulo Contactanos Responsive ===========================================*/
/* Imagen ---------------------*/
.divcontactanosimg-height {
  padding-top: 60px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .divcontactanosimg-height {
    padding-top: 110px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .divcontactanosimg-height {
    padding-top: 70px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .divcontactanosimg-height {
    padding-top: 80px;
  }
}

/* Form ----------------------------------*/

.divcontactanosform-height {
  padding-top: 6%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .divcontactanosform-height {
    padding-top: 30px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .divcontactanosform-height {
    padding-top: 70px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .divcontactanosform-height {
    padding-top: 80px;
  }
}

/* Columnas con el mismo height ------------------------------------------------*/

.row.display-flex {
  display: flex;
  display: -webkit-flex; 
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  /*
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap; */
  margin-left: 0px;
  margin-right: 0px;
}

.row.display-flex:before, .row.display-flex:after{
  display: none;
}

/* Textos Retoques =======================================================================*/
/* Tamaño textos ----------------------------------------------------------*/

@media (max-width: 768px) {
  .title-size { font-size: 5.25vmin; }
  .subtitle-size { font-size: 4.5vmin; }
  .subtitle-size-alt { font-size: 4vmin; }
  .text-size { font-size: 12px; }
}

@media (height: 1024px) {
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .title-size { font-size: 3.5vmin; }
  .subtitle-size { font-size: 3vmin; }
  .subtitle-size-alt { font-size: 2.666vmin; }
  .text-size { font-size: 12px; }
}
}

/* iPad Pro */
@media (height: 1366px) {
  /* Small devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .title-size { font-size: 2.5vmin; }
    .subtitle-size { font-size: 2.14vmin; }
    .subtitle-size-alt { font-size: 1.9047vmin; }
    .text-size { font-size: 12px; }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .title-size {   
    display: block;
    font-size: 2.4em;
    margin-top: (0.67/(2))em;
    margin-bottom: (0.67/(2))em;
    margin-left: 0;
    margin-right: 0;
    line-height : 42px;
    /*font-weight: lighter;*/ }
  .subtitle-size-alt {
    display: block;
    font-size: 1.404em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    /*font-weight: lighter;*/ }
  .subtitle-size {     
    display: block;
    font-size: 15px;
    margin-top: (1/(2))em;
    margin-bottom: (1/(2))em;
    margin-left: 0;
    margin-right: 0;
    /*font-weight: lighter;*/ }
  .text-size {   
    display: block;
    font-size: 14px;
    margin-top: (1.33/(2))em;
    margin-bottom: (1.33/(2))em;
    margin-left: 0;
    margin-right: 0;
    /*font-weight: lighter;*/ }
}

/* Text Align Responsive --------------------------------------------------------*/

.text-justify-xs {
  text-align: center;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .text-justify-sm {
      text-align: left;
  }
}
/* -------------------------------------------- */
.titulo-clientes-padding {
  padding-top: 0px;
   padding-bottom: 5%
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .titulo-clientes-padding {
    padding-top: 12%;
     padding-bottom: 5%
  }
}
/* -------------------------------------------- */
.titulo-contactanos-padding {
  margin-bottom: 5%;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .titulo-contactanos-padding {
    padding-top: 5%;
    padding-bottom: 4%
  }
}

/* -------------------------------------------- */
.plusnum-padding {
  color: #24648F;
  font-size: 9vmin
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .plusnum-padding {
    color: #24648F;
    font-size: 8vmin
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .plusnum-padding {
    color: #24648F;
    font-size: 7vmin
  }
}

/* Img Retoques =======================================================================*/
/* Img Padding Top ----------------------------------------------------------*/

/* Titulo ----------------------------------*/

.imgData-padding-top {
  padding-top: 12%;
  padding-bottom: 10%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .imgData-padding-top {
    padding-top: 8%;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .imgData-padding-top {
    padding-top: 6%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .imgData-padding-top {
    padding-top: 7%;
  }
}

/* Contenido ------------------------------------*/

.img-padding-top {
  padding-top: 0;
  padding-bottom: 10%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .img-padding-top {
    padding-top: 3%;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .img-padding-top {
    padding-top: 6%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .img-padding-top {
    padding-top: 7%;
  }
}

/* Img Size Responsive ----------------------------------------------------------*/

/* Titulo ----------------------------------*/

.imgData-size {
  width: 80%;
  height: auto;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .imgData-size {
    width: 60%;
    height: auto;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .imgData-size {
    width: 100%;
    height: auto;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .imgData-size {
    width: 100%;
    height: auto;
  }
}

/* Cuerpo ----------------------------------*/

.img-size {
  width: 70%;
  height: auto;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .img-size {
    width: 40%;
    height: auto;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .img-size {
    width: 70%;
    height: auto;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .img-size {
    width: 70%;
    height: auto;
  }
}

/* ¿Quienes Somos? ----------------------------------*/

.imgQS-size {
  width: 70%;
  height: auto;
  padding-bottom: 0px
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .imgQS-size {
    width: 80%;
    height: auto;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .imgQS-size {
    width: 100%;
    height: auto;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .imgQS-size {
    width: 100%;
    height: auto;
  }
}

/* Img Border Proveedores ===========================================================*/

.prov-img-border {
  border: 2px solid #26658F;
  border-radius: 100%;
  margin-top: 16%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .prov-img-border {
    border: 3px solid #26658F;
    border-radius: 100%;
    margin-top: 16%;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .prov-img-border {
    border: 2px solid #26658F;
    border-radius: 100%;
    margin-top: 16%;
  }
}

/* Img Margin Proveedores -------------*/
/* Cel */
@media (max-width: 768px) {
  .prov-img-margin-left {
    margin-left: 0px
  }

  .prov-img-margin-right {
    margin-right: 0px
  }
}

/* Carruseles =======================================================================*/
/* Pelotitas del carrusel (ini-clientes)-----------------------------------------------------------*/

div.li-clientes > div.carousel.slide > ol.carousel-indicators {
  top: 111%;
}

div.li-clientes > div.carousel.slide > ol.carousel-indicators > li {
  padding: 1.5%;
  margin-Left: 6%;
  margin-Right: 6%;
}

div.li-clientes > div.carousel.slide > ol.carousel-indicators > li.active {
  padding: 2%;
  margin-Left: 6%;
  margin-Right: 6%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  div.li-clientes > div.carousel.slide > ol.carousel-indicators {
    top: 110%;
  }
  
  div.li-clientes > div.carousel.slide > ol.carousel-indicators > li {
    padding: 1.5%;
    margin-Left: 6%;
    margin-Right: 6%;
  }
  
  div.li-clientes > div.carousel.slide > ol.carousel-indicators > li.active {
    padding: 2%;
    margin-Left: 6%;
    margin-Right: 6%;
  }
}

/* Pelotitas del carrusel (ini-home)-----------------------------------------------------------*/

div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide > ol.carousel-indicators {
  top: 85%;
}

div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide  > ol.carousel-indicators > li {
  padding: 1.5%;
  margin-Left: 6%;
  margin-Right: 6%;
}

div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide  > ol.carousel-indicators > li.active {
  padding: 2%;
  margin-Left: 6%;
  margin-Right: 6%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide > ol.carousel-indicators {
    top: 110%;
  }
  
  div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide > ol.carousel-indicators > li {
    padding: 1.5%;
    margin-Left: 6%;
    margin-Right: 6%;
  }
  
  div.hidden-sm.hidden-md.hidden-lg.hidden-xl.li-home.carousel.slide > ol.carousel-indicators > li.active {
    padding: 2%;
    margin-Left: 6%;
    margin-Right: 6%;
  }
}

/* Form ----------------------------------------------------------------------------*/
/* Input --------*/

.glowing-border {
  border: 2px solid #FDFFFF;
  border-radius: 2px;
}

.glowing-border:focus { 
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
}

/* Form Responsive ----------------------------------------------------------------------------*/

.form-text {
  margin-bottom: 7%;
  width: 100%;
  padding: 1.5%;
}

.form-text-post {
  margin-bottom: 7%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding-top: 1.5%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  padding-bottom: 500px;
}

.form-control {
  width: 100% !important;  
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .form-control {
    width: 100% !important;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .form-control {
    width: 100% !important;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .form-control {
    width: 100% !important;
  }
}

/* Mapa Responsive ----------------------------------------------------------------------------*/

/* Div Map Responsive */

.divmapresponsive div {
  width: 100%;
  padding-left: 30%;
  padding-top: 30%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .divmapresponsive div {
    width: 100%;
    height:100%;
  }
}

.map-container iframe{
  height:100%;
  width:100%;
  padding: 4%;
  }

/* Icons Responsive ----------------------------------------------------------------------------*/

.iconmapresponsive {
  font-size: 10vmin;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .iconmapresponsive {
    font-size: 6vmin;
  }
}

/* Placeholder ----------------------------------------------------------------------------*/
::-webkit-input-placeholder {
  color: #B2B2B2;
  opacity: 1; /* Firefox */
}
::-moz-placeholder {
  color: #B2B2B2;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  color: #B2B2B2;
  opacity: 1; /* Firefox */
}
::-ms-input-placeholder {
  color: #B2B2B2;
  opacity: 1; /* Firefox */
}
::placeholder {
  color: #B2B2B2;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:#B2B2B2;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #B2B2B2;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Tipos de Letras con lighter */
  p, h1, h2, h3, h4, h5, h6 { font-weight: 400/*lighter !important;*/ }
}

/* Shrink Navbar on Scroll  =================================================================================*/
/* Navbar -----------------------------*/
#navbar {
  overflow: hidden;
  -webkit-transition: 1s;
  transition: 1s;
  position: fixed;
  width: 100%;
  top: 0;
}

#navbar a {
  background-color: rgba(0, 0, 0, 0);
}

#navbar a.active {
  background-color: rgba(0, 0, 0, 0);
  color: #434A5D;
  font-weight: 700;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  * {box-sizing: border-box;}

  body { 
    margin: 0;
  }

  #navbar {
    overflow: hidden;
    /*background-color: #EEF3F9;*/
    padding: 30px 10px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
  }

  #navbar a {
    float: left;
    color: #768897;
    text-align: center;
    padding: 12px;
    text-decoration: none; 
    line-height: 25px;
    border-radius: 4px;
  }

  #navbar #logo {
    width: 55%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  @media (max-width: 1199px/*width: 1024px*/) {
    #navbar #logo {
      width: 142px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  
  #navbar a {
    background-color: rgba(0, 0, 0, 0);
    /*color: black;*/
  }

  #navbar a:hover {
    background-color: rgba(0, 0, 0, 0);
    /*color: black;*/
  }

  #navbar a.active {
    background-color: rgba(0, 0, 0, 0);
    color: #434A5D;
    font-weight: 700;
  }

  #navbar-right {
    float: right;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #navbar-right {
      margin-right: 0.4%;
      float: right;
    }
  }

  #navbar-left {
    float: left;
  }

  #a-logo {
    margin-left: 8%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0px;
  }
}

@media (max-width: 1199px/*width: 1024px*/) {
  #a-logo {
    margin-left: 60px;
    margin-bottom: 0px;
    margin-right: 60px;
    width: 100px;
  }

  #logo {
    width: 142px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

@media (min-width: 1200px/*width: 1024px*/) {
  #a-logo {
    margin-left: 2%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0px;
  }
}

@media (min-width: 1400px/*width: 1024px*/) {
  #a-logo {
    margin-left: 6%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0px;
  }
}

@media (min-width: 2000px/*width: 1024px*/) {
  #a-logo {
    margin-left: 18%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0px;
  }
}

#DEMONAV2 {
  display:none;
}

.navbar-button-demo {
  margin-bottom: 0px
}

 /* Small devices (tablets, 768px and up) */
 @media (min-width: 768px) {
  .navbar-button-demo {
    width: 100px; 
    margin-bottom: 0px;
  }
  .navbar-button {
    width: 70px; 
    margin-bottom: 0px;
  }

  #DEMONAV2 {
    display:none;
  }

 }
 
/* Mayor a iPadPro (desktops, 1025px and up) */
@media (min-width: 1025px) {
  .navbar-button-demo {
    width: 160px; 
    margin-bottom: 0px
  }

  #DEMONAV1 {
    display:none;
  }

  #DEMONAV2 {
    display: inline;
  }

 }

.navbar-button {
  margin-bottom: 0px
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Padding Botones Navbar */
  .navbar-button {
    width: 80px; 
    margin-bottom: 0px;
  }

    /* Top and Bottom borders go out ( Lineas Verdes ) =====================================================================*/
  /*.topBotomBordersOut a:before, */.topBotomBordersOut a:after
  {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 4px;
      background: #1ABC9C;
      content: "";
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
  }

  /*.topBotomBordersOut a:before
  {
      top: 6px;
      transform: translateY(10px);
  }
*/
  .topBotomBordersOut a:after
  {
      bottom: 6px;
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
  }

  .topBotomBordersOut a:hover:before, .topBotomBordersOut a:hover:after
  {
      opacity: 1;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
  }
}

/* Img Navbar Datamind Responsive*/
.NavbarImgResp{
  max-width:230px;
  padding-top:10px;
  padding-left: 10px;
  margin-left: 2%;
  height: auto;
  width: 80%
}

/* Button Navbar Cel ------------------------------------------*/
.icon-bar {
  width: 35px;
  height: 5px;
  background-color: #E5E5E5;
  margin: 6px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#bar1.change  {
  -webkit-transform: rotate(-45deg) translate(-5.5px, 5.5px);
  transform: rotate(-45deg) translate(-5.5px, 5.5px);
}

#bar2.change {opacity: 0;}

#bar3.change {
  -webkit-transform: rotate(45deg) translate(-5.5px, -5.5px);
  transform: rotate(45deg) translate(-5.5px, -5.5px);
}

.navbar-toggle.collapsed {
  margin-top: 4px
}

/* ¿CÓMO AYUDAMOS A TU EMPRESA? ===================================================*/
/* PC -----------------------------------*/
@media (min-width: 1200px) {
  .container-img {
    position: relative;
    text-align: center;
  }

  .bottom-left {
    position: absolute;
    bottom: 140px;
    left: 156px;
    text-align: right
  }

  .top-left {
    position: absolute;
    top: 34px;/*8px*/
    left: 96px;/*16*/
    text-align: right
  }

  .top-right {
    position: absolute;
    top: 34px;/*8px*/
    right: 142px;/*16*/
    text-align: left
  }

  .bottom-right {
    position: absolute;
    bottom: 140px;
    right: 116px;
    text-align: left
  }

  .bottom-cate {
    position: absolute;
    top: 96%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center
  }

  .left-cate {
    position: absolute;
    top: 203px;/*8px*/
    left: 30px;/*16*/
    text-align: right
  }

  .right-cate {
    position: absolute;
    top: 203px;/*8px*/
    right: 32px;/*16*/
    text-align: left
  }

  .centered {
    position: absolute;
    top: 39.5%;
    left: 49.9%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@media (max-width: 1199px) {
  .container-img {
    position: relative;
    text-align: center;
  }

  .bottom-left {
    position: absolute;
    bottom: 115px;
    left: 106px;
    text-align: right
  }

  .top-left {
    position: absolute;
    top: 24px;/*8px*/
    left: 46px;/*16*/
    text-align: right
  }

  .top-right {
    position: absolute;
    top: 24px;/*8px*/
    right: 95px;/*16*/
    text-align: left
  }

  .bottom-right {
    position: absolute;
    bottom: 115px;
    right: 66px;
    text-align: left
  }

  .bottom-cate {
    position: absolute;
    top: 96%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center
  }

  .left-cate {
    position: absolute;
    top: 165px;/*8px*/
    left: 0px;/*16*/
    text-align: right
  }

  .right-cate {
    position: absolute;
    top: 165px;/*8px*/
    right: 10px;/*16*/
    text-align: left
  }

  .centered {
    position: absolute;
    top: 39.5%;
    left: 49.9%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.caate-height {
  margin-top: 20px
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .caate-height {
    margin-top: 60px
  }
}

/* CEL-Tablets -----------------------------------*/
/* Texto */
/* Cel */
.exito{
  padding-top: 10%;
  padding-bottom: 10%;
  margin: 0px
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .exito{
    padding-top: 10%;
    padding-bottom: 10%;
    margin: 0px
  }
}

/* Img -------------------------*/

.img-exito {
  width: 80%;
  padding-top: 10%;
  padding-bottom: 10%
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .img-exito {
    width: 45%;
    max-width: 188px;
    padding-top: 0px;
    padding-bottom: 0px
  }

/* Margin de Imgs */
  .ipad-img-caat-left {
    margin-left: 10%
  }

  .ipad-img-caat-right {
    margin-right: 10%
  }
}

/* BLOG ======================================================================================= */

* {
  box-sizing: border-box;
}

/* Add a gray background color with some padding */
.bodyBlog {
  font-family: Arial;
  padding: 20px;
  padding-bottom: 80px;
  background: #266590;
}

.bodyBlogCompleto {
  font-family: Arial;
  padding: 20px;
  background: white;
  padding-top: 150px;
  padding-bottom: 30px;
  background-color: #EEF3F9;
}


/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {   
  float: left;
  width: 75%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  padding-left: 20px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
   background-color: #2189A2;
   color: white;
   padding: 20px;
   margin-top: 20px;
}

/* Add a card effect for articles */
.post {
   background-color: #EEF3F9;
   padding: 20px;
   margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {   
    width: 100%;
    padding: 0;
  }
}
